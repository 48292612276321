import React from "react"

import Layout from "../../components/Layout"
import Download from "../../views/DockingtonReports/Download"

const DownloadPage = ({ location, pageContext: { locale } }) => (
    <Layout location={location} locale={locale}>
        <Download location={location} />
    </Layout>
);

export default DownloadPage;
