import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";

import SEO from '../../components/SEO';
import ScrollToTopOnMount from '../../components/common/ScrollToTopOnMount';
import NavBar from '../../components/common/NavBar';
import Row from '../../components/common/Row';
import Col from '../../components/common/Col';
import Jumbotron from '../../components/common/Jumbotron';

import withTranslate from '../../components/translate';
import processText from '../processText';
import { parseQueryParams } from "../../util";
import loading from "../../assets/images/loading.svg";

import "./Download.css";

const DOWNLOAD_API = "https://europe-west2-dockington.cloudfunctions.net/downloaddr";

const DownloadCard = function ({ url, icon, title, body, onClick }) {
    return (
        <a target="_blank" className="dl-link" href={url} onClick={onClick}>
            <div className={`dl-card ${icon}`}>
                <div className="dl-icon">
                    <FontAwesomeIcon size="4x" icon={[ "fab", icon ]}/>
                </div>
                <div className="dl-body">
                    <div className="dl-title">{title}</div>
                    <p>{processText(body)}</p>
                </div>
            </div>
        </a>
    );
}

const DownloadLinux = function ({ url, translate }) {
    return (
        <DownloadCard
            icon="linux"
            url={url}
            title={translate("reports.download.linux.title")}
            body={translate("reports.download.linux.body")}
            onClick={() => {
                if (window.gtag) {
                    window.gtag('event', "download-dockington-reports-linux", {
                        'event_category' : 'download-dockington-reports',
                        'event_label' : "Download Dockington Reports Linux installer",
                    });
                }
            }}
        />
    );
}

const DownloadWindows = function ({ url, translate }) {
    return (
        <DownloadCard
            icon="windows"
            url={url}
            title={translate("reports.download.windows.title")}
            body={translate("reports.download.windows.body")}
            onClick={() => {
                if (window.gtag) {
                    window.gtag('event', "download-dockington-reports-windows", {
                        'event_category' : 'download-dockington-reports',
                        'event_label' : "Download Dockington Reports Windows installer",
                    });
                }
            }}
        />
    );
}

const DownloadMac = function ({ url, translate }) {
    return (
        <DownloadCard
            icon="apple"
            url={url}
            title={translate("reports.download.mac.title")}
            body={translate("reports.download.mac.body")}
            onClick={() => {
                if (window.gtag) {
                    window.gtag('event', "download-dockington-reports-mac", {
                        'event_category' : 'download-dockington-reports',
                        'event_label' : "Download Dockington Reports macOS installer",
                    });
                }
            }}
        />
    );
}

const Instructions = function ({ icon, title, body }) {
    return (
        <div className="dl-ins">
            <div>
                <FontAwesomeIcon size="lg" icon={[ "fab", icon ]} />
            </div>
            <div className="dl-title">
                {title}
            </div>
            <ol className="dl-points">
                {body.map((point) => (
                    <li>{processText(point)}</li>
                ))}
            </ol>
        </div>
    );
}

const InstructionsMac = function ({ translate }) {
    return (
        <Instructions
            icon="apple"
            title={translate("reports.download.mac.title")}
            body={translate("reports.download.mac.install")}
        />
    );
}
const InstructionsWindows = function ({ translate }) {
    return (
        <Instructions
            icon="windows"
            title={translate("reports.download.windows.title")}
            body={translate("reports.download.windows.install")}
        />
    );
}
const InstructionsLinux = function ({ translate }) {
    return (
        <Instructions
            icon="linux"
            title={translate("reports.download.linux.title")}
            body={translate("reports.download.linux.install")}
        />
    );
}

class Download extends Component {
    state = {
        codeChecked: false,
        linux: "",
        mac: "",
        windows: "",
    };

    componentDidMount() {
        if (this.props.location.search) {
            let query = parseQueryParams(location.search);
            if (!query.code) {
                return;
            }
            axios.post(DOWNLOAD_API, { token: query.code })
            .then((response) => {
                if (response && response.status === 200 && response.data) {
                    this.setState({ codeChecked: true, ...response.data });
                } else {
                    if (window.gtag) {
                        window.gtag('event', "download-dockington-reports-error", {
                            'event_category' : 'download-dockington-reports',
                            'event_label' : "Bad verify purchase response",
                        });
                    }
                    this.setState({ codeChecked: true });
                }
            })
            .catch((err) => {
                if (window.gtag) {
                    window.gtag('event', "download-dockington-reports-error", {
                        'event_category' : 'download-dockington-reports',
                        'event_label' : "Verify purchase failed" + (err.response ? ` with response ${err.response.status}` : ""),
                    });
                }
                this.setState({ codeChecked: true });
            });
        }
    }

    render() {
        const { location, translate } = this.props;
        let downloadCode;
        if (location.search) {
            let query = parseQueryParams(location.search);
            downloadCode = query.code;
        }

        return (
            <div className="dl-page">
                <SEO
                    title={translate("reports.download.head.title")}
                    description={translate("reports.download.head.description")}
                    path="/dockington-reports/download"
                >
                    <meta name="robots" content="noindex" /> {/* Disallow search engines to index this page */}
                </SEO>
                <ScrollToTopOnMount />
                <NavBar translate={translate} />
                <div style={{ minHeight: "65vh" }}>
                    {(!downloadCode || (this.state.codeChecked && (!this.state.linux || !this.state.mac || !this.state.windows))) ?
                    <div key="notfound">
                        <header className="container py-3 mt-4" style={{ textAlign: "center" }}>
                            <div className="intro tagline mt-3">
                                <h1>
                                    {translate("notFound.title")}
                                </h1>
                            </div>
                        </header>
                        <Jumbotron color="#fff" className="text-center mb-5">
                            {translate("notFound.text").map((part) => (
                                <p>{processText(part)}</p>
                            ))}
                        </Jumbotron>
                    </div>
                    :
                    <div key="download" style={{ minHeight: "65vh" }}>
                        {this.state.codeChecked ?
                        <div className="mb-2 pb-2 mb-md-5 pb-md-5">
                            <header className="container py-1 mb-2 mt-md-2 mb-md-4">
                                <div className="mt-3">
                                    <h1>
                                        {processText(translate("reports.download.title"))}
                                    </h1>
                                </div>
                            </header>
                            <div className="container">
                                <h2>{translate("reports.download.selectPlatform")}</h2>
                                <Row className="mb-5 px-1 px-sm-0" justify="center">
                                    <Col xs12 s4 l3>
                                        <DownloadWindows
                                            url={this.state.windows}
                                            translate={translate}
                                        />
                                    </Col>
                                    <Col xs12 s4 l3>
                                        <DownloadMac
                                            url={this.state.mac}
                                            translate={translate}
                                        />
                                    </Col>
                                    <Col xs12 s4 l3>
                                        <DownloadLinux
                                            url={this.state.linux}
                                            translate={translate}
                                        />
                                    </Col>
                                </Row>
                                <h2 className="mb-2">{translate("reports.download.installationTitle")}</h2>
                                <Row className="mb-3" justify="center">
                                    <Col xs12 m4>
                                        <InstructionsWindows
                                            translate={translate}
                                        />
                                    </Col>
                                    <Col xs12 m4 className="dl-ins-border">
                                        <InstructionsMac
                                            translate={translate}
                                        />
                                    </Col>
                                    <Col xs12 m4 className="dl-ins-border">
                                        <InstructionsLinux
                                            translate={translate}
                                        />
                                    </Col>
                                </Row>
                                <p>{processText(translate("reports.download.help"))}</p>
                            </div>
                        </div>
                        :
                        <div style={{ height: "80vh", textAlign: "center", paddingTop: "25%" }}>
                            <div>
                                <img src={loading} />
                            </div>
                            <h2 className="mt-1">{translate("reports.download.loading")}</h2>
                        </div>}
                    </div>}
                </div>
            </div>
        );
    }
}
 
export default withTranslate(Download);
